import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedClickToSearch } from '../../../../../../store/Map/MapSelection/selectors';
import { StyledButton } from '../../../../../Shared/styled-button';
import styled from 'styled-components';
import Analytics from '../../../../../../lib/user-analytics';
import { actionSetSelectedClickToSearch } from '../../../../../../store/Map/MapSelection/actions';

const MapSearchClickToSearch = () => {
    const clickToSearch = useSelector(selectSelectedClickToSearch);
    const dispatch = useDispatch();

    const clickToSearchButtonText = clickToSearch ? 'Deactivate click to Search' : 'Activate click to Search';
    const interactiveText = !clickToSearch
        ? 'Update the search results when you click "Search This Area"'
        : 'Update the search results when you drag and zoom';

    return (
        <ClickToSearchButton
            title={interactiveText}
            onClick={() => {
                dispatch(actionSetSelectedClickToSearch(!clickToSearch));
                Analytics.Event('Side Drawer', `Click to Search ${clickToSearch ? 'Deactivated' : 'Activated'}`);
            }}
        >
            {clickToSearchButtonText}
        </ClickToSearchButton>
    );
};

export default MapSearchClickToSearch;

const ClickToSearchButton = styled(StyledButton)`
    margin: 15px auto 10px auto;
    border-radius: 6px;
    padding: 8px 15px;
`;
