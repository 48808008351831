import styled from 'styled-components';
import { ListingDTO } from '../../../../../api/model';
import ListingHelper from '../../../../../lib/listing-helper';

export interface PreviewTileProps {
    listing: ListingDTO;
    isUserNameDisabled?: boolean;
}

const MapSearchDrawListingCard = ({ listing, isUserNameDisabled }: PreviewTileProps) => {
    const handleImgError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        event.currentTarget.src = '/assets/image-unavailable/preview_unavailable.png';
        event.currentTarget.style.objectFit = 'none';
    };

    return (
        <Container>
            <PreviewImageContainer>
                <PreviewImage
                    src={
                        ListingHelper.getPreviewUrlForListing(listing.id, 'small') ||
                        '/assets/image-unavailable/preview_unavailable.png'
                    }
                    onError={handleImgError}
                />
            </PreviewImageContainer>
            {!isUserNameDisabled ? (
                <ContentContainer>
                    <Title>{listing.title}</Title>
                    <SubText>{`by ${listing.userName || 'Soar User'}`}</SubText>
                </ContentContainer>
            ) : (
                <ProfileContentContainer>
                    <ProfileTitle>{listing.title}</ProfileTitle>
                </ProfileContentContainer>
            )}
        </Container>
    );
};

export default MapSearchDrawListingCard;

const Container = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: horizontal;
    box-shadow: none;
`;

const PreviewImageContainer = styled.div`
    height: 70px;
    width: 70px;
`;

const PreviewImage = styled.img`
    height: 100%;
    width: 100%;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    margin: 0;
    padding: 0;
    object-fit: cover;
`;

const ContentContainer = styled.div`
    display: flex;
    overflow: hidden;
    flex-direction: column;
    width: 75%;
    align-self: end;
    padding: 0px 10px;
`;

const Title = styled.p`
    color: #eed926 !important;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    flex: 1 0 auto;
    margin: 0;
    padding: 0;
`;

const SubText = styled.p`
    color: rgba(255, 255, 255, 0.67);
    white-space: nowrap;
    overflow-x: clip;
    text-overflow: ellipsis;
    font-size: 0.9rem;
`;

const ProfileContentContainer = styled.div`
    display: flex;
    overflow: hidden;
    flex-direction: column;
    width: 75%;
    align-self: center;
    padding: 0px 10px;
`;

const ProfileTitle = styled.p`
    color: #eed926 !important;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;

    flex: 1 0 auto;
    margin: 0;
    padding: 0;
`;
