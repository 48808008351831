import { AppState } from '../../root-reducer';

export const selectListingDictionary = (state: AppState) => state.mapSelectionDomain.listingDictionary;
export const selectGlobalListings = (state: AppState) => state.mapSelectionDomain.globalListings;
export const selectContinentListings = (state: AppState) => state.mapSelectionDomain.continentListings;
export const selectListingDictionaryIsLoading = (state: AppState) =>
    state.mapSelectionDomain.listingDictionaryIsLoading;
export const selectHighlightedContinent = (state: AppState) => state.mapSelectionDomain.highlightedContinent;
export const selectVisibleListingGroups = (state: AppState) => state.mapSelectionDomain.visibleListingGroups;
export const selectBestFittingListingGroups = (state: AppState) => state.mapSelectionDomain.bestFittingListingGroups;
export const selectHighlightedListingGroup = (state: AppState) => state.mapSelectionDomain.highlightedListingGroup;
export const selectHighlightedListingId = (state: AppState) => state.mapSelectionDomain.highlightedListingId;
export const selectSelectedContinent = (state: AppState) => state.mapSelectionDomain.selectedContinent;
export const selectSelectedCategory = (state: AppState) => state.mapSelectionDomain.selectedCategory;
export const selectSearchFocused = (state: AppState) => state.mapSelectionDomain.searchFocused;
export const selectClearListing = (state: AppState) => state.mapSelectionDomain.clearListing;

export const selectSearchDrawListings = (state: AppState) => state.mapSelectionDomain.searchDrawListings;
export const selectSelectedSearchTerm = (state: AppState) => state.mapSelectionDomain.selectedSearchTerm;
export const selectSelectedIsSearchActive = (state: AppState) => state.mapSelectionDomain.selectedIsSearchActive;
export const selectSelectedSearchCategory = (state: AppState) => state.mapSelectionDomain.selectedSearchCategory;
export const selectSelectedSearchOrderBy = (state: AppState) => state.mapSelectionDomain.selectedSearchOrderBy;
export const selectSelectedClickToSearch = (state: AppState) => state.mapSelectionDomain.selectedClickToSearch;
