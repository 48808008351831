import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ListingDTO, ListingType } from '../../../../api/model';
import MapViewHistoryHelper from '../../../../lib/map-view-history-helper';
import SoarHelper from '../../../../lib/soar-helper';
import UriHelper from '../../../../lib/uri-helper';
import Analytics from '../../../../lib/user-analytics';
import { actionUpdateActiveSearchPosition } from '../../../../store/Search/actions';
import { selectAnnotationCommentId } from '../../../../store/SocialMapping/selectors';
import ActiveMapLegend from './active-map-legend';
import ActiveMapOpacitySlider from './active-map-opacity-slider';
import ShareComponent from './Sharing/map-options';
import ProfileName from '../../SideDrawer/ProfileDrawer/Profile/profile-name';
import ActiveMapExternalSourceNotification from './active-map-external-source-notification';
import TemporalCategoryControl from '../../Shared/TemporalCategory/temporal-category-control';
import SideDrawerNested, { DrawIcon } from '../../SideDrawer/Shared/side-drawer-nested';
import ActiveMapComments from './active-map-comments';
import ShareLike from './Sharing/share-like';
import { MarkdownViewer } from '../../../Shared/markdown-viewer';
import ActiveMapBookmark from './active-map-bookmark';
import PinMap from './Sharing/pin-map';
import SoarModal from '../../../Shared/soar-modal';

interface ActiveMapProps {
    listing: ListingDTO;
}

const ActiveMap = (props: ActiveMapProps) => {
    const [displayShareOptions, setDisplayShareOptions] = useState(false);
    const selectedAnnotationCommentId = useSelector(selectAnnotationCommentId);

    const { listing } = props;
    const dispatch = useDispatch();
    const isSoar = SoarHelper.isSoar();

    useEffect(() => {
        MapViewHistoryHelper.addListingToViewedMapsHistory(props.listing);
    }, [props.listing]);

    const handleShowProfile = () => {
        Analytics.Event('Side Drawer', 'Clicked View Profile', listing.userId);
        UriHelper.navigateToPath(`/profile/${listing.userId}`);
    };

    useEffect(() => {
        // Clears the marker if the user selects a map or has added a marker then leaves the map
        // Note: This is an opinionated choice to have the marker removed when a map is selected
        // and could remain on the map until the search input has actually been cleared.
        const handleClearMarker = () => {
            dispatch(actionUpdateActiveSearchPosition(undefined));
        };

        handleClearMarker();

        return () => {
            handleClearMarker();
        };
    }, [dispatch]);

    return (
        <React.Fragment>
            <ActiveMapContent>
                <TemporalCategoryControl listing={listing} margin="0px 0px -5px 0px" showTemporalCategories />
                <ActiveMapTitle title={listing.title}>{listing.title}</ActiveMapTitle>
                <StyledAuthorContainer>
                    by&nbsp;
                    {isSoar ? (
                        <UserProfileLink
                            userId={listing.userId}
                            className="user-profile-link"
                            onClick={handleShowProfile}
                            isCommunityModerator={listing.communityModerator}
                            isReputable={listing.reputable}
                        >
                            {listing.userName ? listing.userName : 'Soar User'}
                        </UserProfileLink>
                    ) : (
                        <React.Fragment>{listing.userName ? listing.userName : 'Soar User'}</React.Fragment>
                    )}
                </StyledAuthorContainer>

                {listing.listingType !== ListingType.WMS && (
                    <UploadDate>{moment(listing.dateUploaded).fromNow()}</UploadDate>
                )}
                <ActiveMapOpacitySliderContainer>
                    <ActiveMapOpacitySlider tileLayer={listing} />
                    <PinShare>
                        <PinMap />
                    </PinShare>
                </ActiveMapOpacitySliderContainer>
                <ActiveMapExternalSourceNotification listing={listing} />
                <OptionsContainer>
                    <OptionSection>
                        <BookmarkShare>
                            <ActiveMapBookmark mapId={listing.id} />
                        </BookmarkShare>
                        <ShareDrawIcon
                            title="Sharing & More"
                            className="fa fa-share"
                            onClick={() => {
                                Analytics.Event('Side Drawer', 'Clicked To View Share Modal', listing.userId);
                                setDisplayShareOptions(true);
                            }}
                        />
                    </OptionSection>
                    <OptionSection>
                        <LikeShare>
                            <ShareLike selectedMap={listing} />
                        </LikeShare>
                        <SideDrawerNested
                            key={listing.id}
                            controlText="Comment"
                            controlIcon={<i className="fa fa-comment" />}
                            defaultOpen={selectedAnnotationCommentId ? true : false}
                        >
                            <ActiveMapComments key={listing.id} listing={listing} />
                        </SideDrawerNested>
                    </OptionSection>
                </OptionsContainer>
                <TemporalCategoryControl
                    listing={listing}
                    fontSize="11px"
                    margin="10px 0px 0px 0px"
                    showDefaultCategories
                />
                <Content>
                    {listing?.description ? <MarkdownViewer text={listing.description} /> : null}
                    <ActiveMapLegend listing={listing} />
                    <TagContainer>
                        {listing.tags &&
                            listing.tags.map((tag, index) => {
                                return <Tag key={`${tag}-${index}`}>{tag}</Tag>;
                            })}
                    </TagContainer>
                </Content>
            </ActiveMapContent>
            <SoarModal
                isOpen={displayShareOptions}
                toggle={() => setDisplayShareOptions(false)}
                title="Sharing & more"
                width="400px"
            >
                <ShareComponent />
            </SoarModal>
        </React.Fragment>
    );
};

export default ActiveMap;

const ActiveMapContent = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 10px 0px;
    overflow-y: auto;
`;

const ActiveMapTitle = styled.h1`
    font-weight: 700;
    font-size: 1.8rem;
    color: white;
    margin: 12px;
    text-align: left;
    max-width: 290px;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
    line-height: 2.5rem;
    word-break: break-word;
    hyphens: auto;
`;

const StyledAuthorContainer = styled.span`
    display: flex;
    flex-direction: row;
    margin: 0px 0px -7px 12px;
    padding: 0 6px 4px 0;
    color: white;
    font-size: 16px;

    p {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        padding-left: 3px;
        align-self: center;
        cursor: help;
        height: 17px;
    }
`;

const UserProfileLink = styled(ProfileName)`
    display: inline-flex;
    width: auto;
    max-width: 90%;
    font-size: 16px !important;
    margin: 0 !important;

    a {
        min-width: 0;
        color: #eed926 !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: normal;
    }
`;

const ActiveMapOpacitySliderContainer = styled.div`
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);

    .opacity-slider {
        padding: 15px 12px;
        border-bottom: none;
    }
`;

const UploadDate = styled.span`
    margin-left: 12px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 1rem;
`;

const Content = styled.div`
    padding: 10px;
    font-weight: 400;
    line-height: 22.8px;
    color: white;
    word-break: break-word;
    overflow-y: auto;
    height: calc(100vh - 390px);

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #eed926;
        border-radius: 4px;
    }
`;

const TagContainer = styled.div`
    flex: 0 1 auto;
    text-align: left;
    display: flex;
    margin: 10px 0px;
    flex-wrap: wrap;
    max-height: auto;
    overflow: visible;
`;

const Tag = styled.div`
    border: 1px solid rgba(255, 255, 255, 0.67);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 6px;
    padding: 3px;
    line-height: 14px;
    margin: 0px 2px 2px 0px;
    color: white;
    font-size: 12px;
`;

const OptionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 6px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`;

const OptionSection = styled.div`
    display: flex;
    flex-direction: row;
`;

// TODO refactor the share/ option components as they have some opinionated paddings and margins but they are used multiple places so for now we will leave them as is
// Annoying to to this but will need some time spent on it to refactor
const PinShare = styled.div`
    margin: 20px 10px 0px 5px;
`;

const BookmarkShare = styled.div`
    padding: 0px 5px;
    margin: 7px 0px 0px 2px;
`;

const LikeShare = styled.div`
    margin: 1px 0px 0px 0px;
    padding: 8px 0;
`;

const ShareDrawIcon = styled(DrawIcon)`
    margin: 1px 0px 0px 3px;
`;
