import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import styled from 'styled-components';
import moment from 'moment';
import L from 'leaflet';

import ApiDraw from '../../../../api/api-draw';
import { DrawProjectDTO, AnalyticsAction, AnalyticsNote } from '../../../../api/model';
import ApiAnalytics from '../../../../api/api-analytics';
import { selectLeafletMap } from '../../../../store/App/selectors';
import { adjustedMapBounds } from '../ListingsDrawer/map-search-draw-util';

import SideDrawerStoarySearch from './side-drawer-stoary-search';
import SideDrawerInfiniteList, { ItemType } from '../Shared/side-drawer-infinite-list';

import Analytics from '../../../../lib/user-analytics';
import UriHelper from '../../../../lib/uri-helper';
import GeoUtil from '../../../../lib/geo-util';

const RESULT_LIMIT = 25;
const LOAD_MORE_LIMIT = 20;

const MAP_MOVE_DEBOUNCE = 250;
const WORLD_BOUNDS = L.latLngBounds(L.latLng(-90, -180), L.latLng(90, 180));

const SideDrawerStoaries = () => {
    const [searchWord, setSearchWord] = useState<string>('');
    const [drawProjects, setDrawProjects] = useState<DrawProjectDTO[] | undefined>(undefined);
    const [hasReachedEnd, setHasReachedEnd] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const history = useHistory();
    const leafletMap = useSelector(selectLeafletMap);

    const CancelTokenSearch = () => ApiDraw.cancelSearchDrawProjects('User Updated Search Term');

    console.log('SideDrawerStoaries', { isLoading });

    const fetchStoaries = useCallback(
        (offset: number, limit?: number, searchTerm?: string) => {
            console.log('fetchStoaries', { offset, limit, searchTerm });
            setIsLoading(true);

            let aoiWKT = '';

            if (leafletMap) {
                const WORLD_BOUNDS_ZOOM_CUTOFF = 3;
                const ALL_BOUNDS_PADDING = 0;
                const NAV_HEIGHT = 70;
                const DRAWER_WIDTH = 405;

                const applyWorldBounds = leafletMap.getZoom() < WORLD_BOUNDS_ZOOM_CUTOFF ? true : false;

                const mapBounds = adjustedMapBounds(leafletMap, {
                    top: NAV_HEIGHT + ALL_BOUNDS_PADDING,
                    right: ALL_BOUNDS_PADDING,
                    bottom: ALL_BOUNDS_PADDING,
                    left: DRAWER_WIDTH + ALL_BOUNDS_PADDING,
                });

                aoiWKT = GeoUtil.latLngBoundsToWKT(applyWorldBounds ? WORLD_BOUNDS : mapBounds);
            }

            ApiDraw.getAndSearchProjects(offset, limit ?? RESULT_LIMIT, searchTerm, aoiWKT)
                .then((rawDrawProjects) => {
                    if (rawDrawProjects.length < (limit ?? RESULT_LIMIT)) {
                        setHasReachedEnd(true);
                    }

                    const updaatedDrawProjects =
                        offset === 0 ? rawDrawProjects : [...(drawProjects ?? []), ...rawDrawProjects];

                    setDrawProjects(updaatedDrawProjects as DrawProjectDTO[]);
                })
                .catch((error) => {
                    console.log('Error fetching stoaries:', error);
                })
                .finally(() => setIsLoading(false));
        },
        [drawProjects, leafletMap]
    );

    // on map move, fetch stoaries
    useEffect(() => {
        if (!leafletMap) return;
        const debouncedHandleMapMove = debounce(() => {
            setDrawProjects(undefined);
            CancelTokenSearch();
            fetchStoaries(0, RESULT_LIMIT, searchWord);
        }, MAP_MOVE_DEBOUNCE);

        leafletMap.on('moveend', debouncedHandleMapMove);
        leafletMap.on('zoomend', debouncedHandleMapMove);

        return () => {
            leafletMap.off('moveend', debouncedHandleMapMove);
            leafletMap.off('zoomend', debouncedHandleMapMove);
        };
    }, [leafletMap, fetchStoaries, searchWord]);

    useEffect(() => {
        setDrawProjects([]);
        if (searchWord) {
            CancelTokenSearch();
            fetchStoaries(0, RESULT_LIMIT, searchWord);
        } else {
            fetchStoaries(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchWord]);

    useEffect(() => {
        if (drawProjects === undefined) {
            fetchStoaries(0);
        }

        return () => {
            setDrawProjects(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoadMore = () => {
        console.log('handleLoadMore');
        if (drawProjects) {
            fetchStoaries(drawProjects.length, LOAD_MORE_LIMIT, searchWord);
        }
    };

    const handleItemClick = (item: ItemType, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();

        history.push(UriHelper.stripOrigin(window.location.href));
        ApiAnalytics.postAnalyticsListing(AnalyticsAction.VIEW, AnalyticsNote.STOARY, item.id);
        Analytics.Event('Side Drawer', 'Clicked To View Stoary', item.id);
        UriHelper.navigateToPath(`/draw/${item.id}`);
    };

    return (
        <StoariesDrawer>
            <StoariesTitle>Stoaries</StoariesTitle>
            <SideDrawerStoarySearch searchWord={''} setSearchhWord={setSearchWord} />
            <SideDrawerInfiniteScrollContainer>
                <SideDrawerInfiniteList
                    type="Stoaries"
                    items={drawProjects}
                    exitingItems={new Set()}
                    itemsCount={drawProjects?.length || 0}
                    filteredCount={drawProjects?.length || 0}
                    isLoading={isLoading}
                    hasMoreToLoad={!hasReachedEnd}
                    heightPadding="175px"
                    onLoadMore={handleLoadMore}
                    onClick={handleItemClick}
                    getItemLink={(item: ItemType) => `/draw/${item.id}`}
                    getItemCard={(item: DrawProjectDTO) => (
                        <StoariesItemCard>
                            <StoaryTitle>{item.title || <StoaryNoTitle />}</StoaryTitle>
                            <StoaryAuthor>{item.ownerName || 'Soar User'}</StoaryAuthor>
                            <StoaryDate>{moment(item.createdAt * 1000).fromNow()}</StoaryDate>
                        </StoariesItemCard>
                    )}
                />
            </SideDrawerInfiniteScrollContainer>
        </StoariesDrawer>
    );
};

export default SideDrawerStoaries;

const StoariesDrawer = styled.div`
    margin-top: 15px;
    * {
        user-select: none;
    }
`;

const StoariesTitle = styled.h1`
    text-align: center;
    font-size: 22px;
    color: white;
`;

const SideDrawerInfiniteScrollContainer = styled.div`
    margin: 0px 0px 0px 7px;
`;

const StoariesItemCard = styled.div`
    color: ${(props) => props.theme.color.white};
    display: flex;
    flex-direction: column;
    padding: 4px 8px;
`;

const StoaryTitle = styled.h3`
    font-size: ${(props) => props.theme.fontSize.large};
    color: ${(props) => props.theme.color.yellow};
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
`;

const StoaryNoTitle = styled.em`
    &:before {
        content: 'Untitled Stoary';
        color: ${(props) => props.theme.color.opaqueWhite};
    }
`;

const StoaryAuthor = styled.p`
    color: ${(props) => props.theme.color.white};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;

    &:before {
        content: 'Stoary by: ';
        color: ${(props) => props.theme.color.opaqueWhite};
    }
`;

const StoaryDate = styled.p`
    color: ${(props) => props.theme.color.opaqueWhite};
    margin-bottom: 0;
`;
