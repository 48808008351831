import { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import MapSearchDrawAOIResults from './SearchListings/map-search-draw-aoi-results';
import MapSearchDrawListingCount from './SearchListings/map-search-draw-listing-count';
import { ListingDTO } from '../../../../api/model';
import ApiListings from '../../../../api/api-listings';
import GeoUtil from '../../../../lib/geo-util';
import {
    selectSelectedClickToSearch,
    selectSelectedIsSearchActive,
    selectSelectedSearchCategory,
    selectSelectedSearchOrderBy,
    selectSelectedSearchTerm,
} from '../../../../store/Map/MapSelection/selectors';
import MapNestedSideDrawer from '../Shared/side-drawer-nested';
import MapSearchNestedFilterContent from './SearchListings/map-search-nested-filter-content';
import debounce from 'lodash/debounce';
import useWidthObserver from '../Shared/use-width-observer';
import { adjustedMapBounds } from './map-search-draw-util';
import { selectLeafletMap } from '../../../../store/App/selectors';
import MapSearchNestedFilterIcon from './SearchListings/map-search-nested-filter-icon';
import { ORDER_BY_DEFAULT, ORDER_BY_KEYWORDS } from './SearchListings/SearchListingsFilters/map-search-orderby-filter';
import { CATEGORY_DEFAULT } from './SearchListings/SearchListingsFilters/map-search-category-filter';
import { StyledButton } from '../../../Shared/styled-button';
import { PulseLoader } from '../../../Shared/pulse-loader';
import L from 'leaflet';

const RESULT_LIMIT = 25; // Api default
const LOAD_MORE_LIMIT = 20;
const MAP_MOVE_DEBOUNCE = 250;
const WORLD_BOUNDS = L.latLngBounds(L.latLng(-90, -180), L.latLng(90, 180));

const MapSearchDraw = () => {
    const [listings, setListings] = useState<ListingDTO[] | undefined>();
    const [listingLocationCount, setListingLocationCount] = useState<number | undefined>();
    const [listingsFilteredCount, setListingsFilteredCount] = useState<number | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const leafletMap = useSelector(selectLeafletMap);

    const threshold = 290; // Below threshold to autoclose advanced search if open
    const { elementRef, isBelowThreshold } = useWidthObserver(threshold);

    const searchTerm = useSelector(selectSelectedSearchTerm);
    const isSearchActive = useSelector(selectSelectedIsSearchActive);
    const searchCategory = useSelector(selectSelectedSearchCategory);
    const searchOrderBy = useSelector(selectSelectedSearchOrderBy);
    const clickToSearch = useSelector(selectSelectedClickToSearch);

    const fetchListings = useCallback(
        async (offset: number, loadMoreLimit?: number) => {
            setIsLoading(true);
            if (!leafletMap) return;
            try {
                const WORLD_BOUNDS_ZOOM_CUTOFF = 3;
                const ALL_BOUNDS_PADDING = 0; // Apply padding to the user's viewport
                const NAV_HEIGHT = 70;
                const DRAWER_WIDTH = 405;

                // Apply world bounds if zoomed out too far
                const applyWorldBounds = leafletMap.getZoom() < WORLD_BOUNDS_ZOOM_CUTOFF ? true : false;

                // Apply padding to the user's viewport
                const mapBounds = adjustedMapBounds(leafletMap, {
                    top: NAV_HEIGHT + ALL_BOUNDS_PADDING,
                    right: ALL_BOUNDS_PADDING,
                    bottom: ALL_BOUNDS_PADDING,
                    left: DRAWER_WIDTH + ALL_BOUNDS_PADDING,
                });

                // Convert bounds to be used to WKT
                const aoiWKT = GeoUtil.latLngBoundsToWKT(applyWorldBounds ? WORLD_BOUNDS : mapBounds);

                const handleOrderBy = isSearchActive ? ORDER_BY_KEYWORDS : searchOrderBy || ORDER_BY_DEFAULT;

                // Fetch listings
                const data = await ApiListings.fetchListingsByAOI(
                    aoiWKT,
                    loadMoreLimit ? loadMoreLimit : RESULT_LIMIT,
                    offset,
                    searchCategory?.toLowerCase() || CATEGORY_DEFAULT,
                    handleOrderBy,
                    searchTerm
                );

                if (data?.listings) {
                    if (!loadMoreLimit) {
                        setListings(data.listings);
                    } else {
                        setListings((prevListings) => [...(prevListings ?? []), ...data.listings]);
                    }
                    setListingLocationCount(data.locationTotal);
                    setListingsFilteredCount(data.filteredTotal);
                }
            } catch (error) {
                // Cancelled requests are not errors is hwo the api.ts is setup though...
                if (!error.toString().includes('Cancel')) {
                    console.error('Error fetching listings:', error);
                }
            } finally {
                setTimeout(() => setIsLoading(false), 300);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [leafletMap, searchCategory, searchOrderBy, searchTerm]
    );

    const CancelToken = () => ApiListings.cancelAOIListing('User Updated AOI');

    useEffect(() => {
        if (clickToSearch) {
            requestAnimationFrame(() => {
                CancelToken();
                fetchListings(0);
            });
        }
    }, [clickToSearch, fetchListings]);

    useEffect(() => {
        if (clickToSearch) return;
        CancelToken();
        fetchListings(0);
    }, [searchTerm, searchCategory, searchOrderBy, clickToSearch, fetchListings]);

    useEffect(() => {
        if (!leafletMap) return;
        const debouncedHandleMapMove = debounce(() => {
            if (clickToSearch) return;
            CancelToken();
            fetchListings(0);
        }, MAP_MOVE_DEBOUNCE);

        leafletMap.on('moveend', debouncedHandleMapMove);
        leafletMap.on('zoomend', debouncedHandleMapMove);

        if (clickToSearch) {
            CancelToken();
            setListings([]);
            leafletMap.off('moveend', debouncedHandleMapMove);
            leafletMap.off('zoomend', debouncedHandleMapMove);
        }

        return () => {
            leafletMap.off('moveend', debouncedHandleMapMove);
            leafletMap.off('zoomend', debouncedHandleMapMove);
        };
    }, [leafletMap, fetchListings, clickToSearch]);

    const handleLoadMore = debounce(() => {
        CancelToken();
        fetchListings(listings?.length ?? 0, LOAD_MORE_LIMIT);
    }, MAP_MOVE_DEBOUNCE);

    return (
        <MapSearchDrawContainer ref={elementRef}>
            <MapNestedSideDrawer
                controlText="Advanced Filter"
                controlComponent={<MapSearchNestedFilterIcon />}
                closeDrawer={isBelowThreshold}
            >
                <MapSearchNestedFilterContent />
            </MapNestedSideDrawer>
            <MapSearchDrawListingCount listingLocationCount={listingLocationCount} />
            {clickToSearch && (
                <ClickToSearchButton onClick={() => fetchListings(0)} disabled={isLoading} isLoading={isLoading}>
                    {!isLoading ? 'Search This Area' : <PulseLoader />}
                </ClickToSearchButton>
            )}
            <MapSearchResultsDivider />
            <MapSearchDrawAOIResults
                listings={listings}
                listingsCount={listingLocationCount || listingsFilteredCount || 0}
                filteredCount={listingsFilteredCount || 0}
                isLoading={isLoading}
                onLoadMore={handleLoadMore}
            />
        </MapSearchDrawContainer>
    );
};

export default MapSearchDraw;

const MapSearchDrawContainer = styled.div`
    margin: 0px 0px 0px 7px;
`;

const MapSearchResultsDivider = styled.div`
    height: 1px;
    background-color: rgba(255, 255, 255, 0.1);
    margin-top: 10px;
`;

const ClickToSearchButton = styled(StyledButton)<{ isLoading: boolean }>`
    width: 182px;
    font-weight: 600;
    position: fixed;
    top: 90px;
    left: calc(50vw - 91px);
    z-index: 0;
    background-color: ${({ isLoading }) => (isLoading ? 'rgba(0, 0, 0, 0.8) !important' : '#eed926')};
    box-shadow: rgba(0, 0, 0, 0.3) 0px 11px 14px -7px, rgba(0, 0, 0, 0.24) 0px 23px 36px 3px,
        rgba(0, 0, 0, 0.22) 0px 9px 44px 8px;
    border: 1px solid rgb(81, 81, 81);
    &:hover {
        opacity: 0.8;
    }
`;
