import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import ApiListings from '../../../../api/api-listings';
import { ListingDTO } from '../../../../api/model';
import FeaturedResultsMasonary from './featured-results-masonary';
import { PulseLoader } from '../../../Shared/pulse-loader';
import FeaturedNoResults from './featured-no-results';

const LIMIT = 40;

interface SearchResultsProps {
    selectedSearchTerm: string;
    selectedOrder: string;
    children?: ReactNode;
}

const FeaturedSearchResults = (props: SearchResultsProps) => {
    const [isNoItems, setIsNoItems] = useState(false);
    const [items, setItems] = useState<ListingDTO[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchListings = (limit: number, offset: number) => {
        setIsLoading(true);
        const params = {
            limit: limit || 25,
            offset: offset || 0,
            keywords: props.selectedSearchTerm || undefined,
            orderBy: props.selectedOrder || 'CREATED',
        };

        ApiListings.sideDrawerSearchFeaturedListings(params)
            .then((res) => {
                // Filter duplicates
                const newItems = res.filter((t) => !items.includes(t));
                if (res.length === 0 && newItems.length === 0) {
                    setIsNoItems(true);
                    setIsLoading(false);
                    return;
                }

                setIsNoItems(false);
                setItems((current) => [...current, ...newItems]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        setItems([]);
        fetchListings(LIMIT, 0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedSearchTerm, props.selectedOrder]);

    const maybeLoadMore = () => {
        fetchListings(items.length + LIMIT, items.length);
    };
    return (
        <React.Fragment>
            {isLoading && !items.length ? (
                <SearchLoading>
                    <PulseLoader />
                </SearchLoading>
            ) : (
                <React.Fragment>
                    {items.length > 0 ? (
                        <FeaturedResultsMasonary
                            items={items}
                            maybeLoadMore={maybeLoadMore}
                            isNoItems={isNoItems}
                            selectedOrder={props.selectedOrder}
                        >
                            {props.children}
                        </FeaturedResultsMasonary>
                    ) : null}
                    {items.length === 0 && !isLoading && isNoItems ? <FeaturedNoResults /> : null}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default FeaturedSearchResults;

const SearchLoading = styled.div`
    position: absolute;
    width: 100%;
    margin: 0 auto;
    top: 50dvh;
`;
