import { useState } from 'react';
import styled from 'styled-components';

interface SideDrawerStoarySearchProps {
    searchWord: string;
    setSearchhWord: (searchWord: string) => void;
}

const SideDrawerStoarySearch = ({ searchWord, setSearchhWord }: SideDrawerStoarySearchProps) => {
    const [searchTerm, setSearchTerm] = useState(searchWord ?? '');

    const handleClearSearch = () => {
        setSearchTerm('');
        setSearchhWord('');
    };

    return (
        <SearchContainer>
            <SearchIcon className="fa fa-search" />
            <SearchInput
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setSearchhWord(e.target.value);
                }}
            />
            {searchTerm && <ClearIcon className="fa fa-times" onClick={handleClearSearch} />}
        </SearchContainer>
    );
};

export default SideDrawerStoarySearch;

const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    width: 97%;
    height: 40px;
    margin: 10px 0px 5px 5px;
`;

const SearchIcon = styled.i`
    padding: 10px;
    color: white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
`;

const SearchInput = styled.input`
    width: 100%;
    padding: 3px 30px;
    background: transparent;
    color: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 6px;
    height: 35px;

    :focus {
        outline: none;
    }
`;

const ClearIcon = styled.i`
    padding: 10px;
    font-size: 16px;
    color: white;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
`;
