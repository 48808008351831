import styled, { keyframes } from 'styled-components';
import UriHelper from '../../../../lib/uri-helper';
import Analytics from '../../../../lib/user-analytics';
import { StyledButton } from '../../../Shared/styled-button';
import { MapDrawerTypes } from './side-drawer-infinite-list';

interface SideDrawerNoMapsProps {
    type: MapDrawerTypes;
}

// TODO make it smarted smarter and check if the user has a search term to make the message less generalised
const SideDrawerNoMaps = ({ type }: SideDrawerNoMapsProps) => {
    const handleNoMapType = (type: MapDrawerTypes) => {
        switch (type) {
            case 'MapSearch':
                return {
                    eventName: 'Map Search',
                    eventIcon: '/assets/dashboard/maps_yellow.svg',
                    eventText: 'No maps found with your search criteria!',
                };
            case 'Bookmarks':
                return {
                    eventName: 'Bookmarks',
                    eventIcon: '/assets/dashboard/bookmark_yellow.svg',
                    eventText: 'No bookmarked maps displayed, click the bookmark icon on a map to save it!',
                    buttonText: 'Find Maps',
                    buttonPath: '/maps',
                    buttonIcon: '/assets/floating-drawer-icons/maps-icon-black.svg',
                };
            case 'MyMaps':
                return {
                    eventName: 'My Maps',
                    eventIcon: '/assets/dashboard/my-maps_yellow.svg',
                    eventText: 'No maps found, add some!',
                    buttonText: 'Upload Map',
                    buttonPath: '/upload',
                    buttonIcon: '/assets/floating-drawer-icons/maps-icon-black.svg',
                };
            case 'Stoaries':
                return {
                    eventName: 'Stoaries',
                    eventIcon: '/assets/dashboard/draw_yellow.svg',
                    eventText: 'No Stoaries found, add some!',
                    buttonText: 'Create a Stoary',
                    buttonPath: '/draw/new',
                    buttonIcon: '/assets/dashboard/draw_black.svg',
                };
            default:
                return null;
        }
    };

    const noMapDetails = handleNoMapType(type as MapDrawerTypes);

    const handleClickedFindMaps = (eventName: string, eventPath: string) => {
        if (noMapDetails) {
            Analytics.Event('Side Drawer', eventName, 'No Maps Found');
            UriHelper.navigateToPath(eventPath);
        }
    };

    return (
        <NoMapContainer>
            <NoMapCTA>
                <NoMapCTAImage src={noMapDetails?.eventIcon} />
                <NoMapText>{noMapDetails?.eventText}</NoMapText>
            </NoMapCTA>
            {noMapDetails?.buttonText ? (
                <NoMapButton onClick={() => handleClickedFindMaps(noMapDetails.eventName, noMapDetails.buttonPath)}>
                    <NoMapButtonIcon src={noMapDetails?.buttonIcon} />
                    {noMapDetails?.buttonText}
                </NoMapButton>
            ) : null}
        </NoMapContainer>
    );
};

export default SideDrawerNoMaps;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const NoMapContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
    cursor: pointer;
    height: auto;
    border-radius: 6px;
    overflow: hidden;
    display: block;
    position: relative;
    margin: 0px 5px;
    border: 1px solid rgba(238, 218, 38, 0.8);
    background: rgba(0, 0, 0, 0.15);
    text-decoration: none;
    opacity: 0;
    animation: ${fadeIn} 0.5s ease-in-out 0.3s forwards;
`;

const NoMapText = styled.div`
    color: rgba(255, 255, 255, 0.87);
    font-size: 16px;
    align-content: center;
    margin-left: 10px;
`;

const NoMapCTA = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const NoMapCTAImage = styled.img`
    height: 50px;
    width: auto;
    margin: auto 0px;
`;

const NoMapButton = styled(StyledButton)`
    margin: 12px auto;
    display: block;
    width: 96%;
    text-transform: uppercase;
`;

const NoMapButtonIcon = styled.img`
    width: 25px;
    margin: -4px 7px 0px 5px;
`;
