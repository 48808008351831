import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectSelectedSearchTerm } from '../../../../store/Map/MapSelection/selectors';
import FeaturedSearchInput from './featured-search-input';
import FeaturedSearchResults from './featured-search-results';
import SearchContentHistory from '../../../Search/search-content-history';
import SearchContentWhatsHot from '../../../Search/search-content-whats-hot';
import SearchContentCurrentEvents from '../../../Search/search-content-current-events';
import SearchContentNearbyMaps from '../../../Search/search-content-nearby-maps';
import UriHelper from '../../../../lib/uri-helper';
import FeaturedSearchFilter from './featured-search-filter';
import Analytics from '../../../../lib/user-analytics';

const SideDrawerFeatured = () => {
    const [searchOrderBy, setSearchOrderBy] = useState<string>('');
    const selectedSearchTerm = useSelector(selectSelectedSearchTerm);

    const handleSelectListing = (listingId: number) => {
        Analytics.Event('Side Drawer', `Select Featured Listing: ${listingId}`);
        UriHelper.navigateToPath(`/maps/${listingId}`);
    };

    const handleSelectProfile = (userId: string) => {
        Analytics.Event('Side Drawer', `Select Featured Profile: ${userId}`);
        UriHelper.navigateToPath(`/profile/${userId}`);
    };

    const handleOrderBySelector = selectedSearchTerm && selectedSearchTerm.length > 0 ? 'RELEVANCE' : searchOrderBy;
    return (
        <FeaturedMasonryContainer>
            <FeaturedSearchControl>
                <FeaturedSearchInput />
                <FeaturedSearchFilter searchOrderBy={handleOrderBySelector} setSearchOrderBy={setSearchOrderBy} />
            </FeaturedSearchControl>
            <FeaturedSearchResults selectedSearchTerm={selectedSearchTerm || ''} selectedOrder={searchOrderBy}>
                {!selectedSearchTerm && !searchOrderBy ? (
                    <React.Fragment>
                        <SearchContentWhatsHot handleSelectListing={handleSelectListing} />
                        <SearchContentHistory handleSelectListing={handleSelectListing} />
                        <SearchContentCurrentEvents
                            handleSelectListing={handleSelectListing}
                            handleSelectProfile={handleSelectProfile}
                        />
                        <SearchContentNearbyMaps handleSelectListing={handleSelectListing} />
                    </React.Fragment>
                ) : null}
            </FeaturedSearchResults>
        </FeaturedMasonryContainer>
    );
};

export default SideDrawerFeatured;

const FeaturedMasonryContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100vw - 80px);
    height: calc(100vh - 75px);
`;

const FeaturedSearchControl = styled.div`
    display: flex;
    flex-direction: row;
`;
